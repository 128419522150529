import React from 'react';

export interface HelpSvgProps {}

const HelpSvg: React.FunctionComponent<HelpSvgProps> = () => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.6208 0C4.10302 0 3.68113 0.421894 3.68113 0.939672V14.6001C3.68113 15.1242 3.50214 15.6101 3.19531 16H16.1845C16.9133 16 17.5077 15.4055 17.5077 14.6768V0.939672C17.5077 0.421894 17.0858 0 16.5681 0H4.6208ZM14.791 10.6802H10.8953C10.6524 10.6802 10.4542 10.482 10.4542 10.2391C10.4542 9.99622 10.6524 9.79805 10.8953 9.79805H14.7974C15.0403 9.79805 15.2385 9.99622 15.2385 10.2391C15.2321 10.482 15.0403 10.6802 14.791 10.6802ZM14.791 8.04656H6.23833C5.99542 8.04656 5.79726 7.84839 5.79726 7.60549C5.79726 7.36258 5.99542 7.16442 6.23833 7.16442H14.791C15.0339 7.16442 15.2321 7.36258 15.2321 7.60549C15.2321 7.85479 15.0403 8.04656 14.791 8.04656ZM14.791 5.41931H6.23833C5.99542 5.41931 5.79726 5.22115 5.79726 4.97824C5.79726 4.73533 5.99542 4.53717 6.23833 4.53717H14.791C15.0339 4.53717 15.2321 4.73533 15.2321 4.97824C15.2321 5.22115 15.0403 5.41931 14.791 5.41931Z'
      fill='white'
    />
    <path
      d='M0 14.594C0 15.4122 0.709549 16.0706 1.54694 15.9875C2.27567 15.9108 2.79984 15.246 2.79984 14.5109V11.5576H0.939672C0.421894 11.5576 0 11.9795 0 12.4973V14.594Z'
      fill='white'
    />
  </svg>
);

export default HelpSvg;
